import { useStoreActions, useStoreState } from '@/common/providers/easy-peasy/hooks';

function useScrollStore() {
  var currentPageStoredScrollPosition = useStoreState(function (store) {
    return store.scroll.currentPageStoredScrollPosition;
  });
  var prevUri = useStoreState(function (store) {
    return store.scroll.prevUri;
  });
  var setStoredScrollPosition = useStoreActions(function (actions) {
    return actions.scroll.setStoredScrollPosition;
  });
  var getStoredScrollPosition = useStoreActions(function (actions) {
    return actions.scroll.getStoredScrollPosition;
  });
  var setPrevUri = useStoreActions(function (actions) {
    return actions.scroll.setPrevUri;
  });
  return {
    currentPageStoredScrollPosition: currentPageStoredScrollPosition,
    setStoredScrollPosition: setStoredScrollPosition,
    getStoredScrollPosition: getStoredScrollPosition,
    prevUri: prevUri,
    setPrevUri: setPrevUri
  };
}

export { useScrollStore };