import { useEffect, useState } from 'react';
import { useEther } from '@/common/hooks';

var EtherPriceWithLabel = function EtherPriceWithLabel(amount) {
  var _useEther = useEther(),
      storedUsdPriceInEth = _useEther.storedUsdPriceInEth;

  var _useState = useState(null),
      etherPrice = _useState[0],
      setEtherPrice = _useState[1];

  useEffect(function () {
    if (storedUsdPriceInEth && amount > 0) {
      var calculatedPrice = amount / 100 * storedUsdPriceInEth;
      var fourDecimals = calculatedPrice < 0.005 ? 0.01 : calculatedPrice;
      setEtherPrice(parseFloat(fourDecimals.toFixed(4)));
    }
  }, [amount, storedUsdPriceInEth]);

  if (!etherPrice) {
    return null;
  }

  return etherPrice;
};

export { EtherPriceWithLabel };