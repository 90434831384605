import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { useAuth } from '@/common/hooks';

function useAuthenticatedLazyQuery(query, options) {
  var _options$context;

  var _useAuth = useAuth(),
      accessToken = _useAuth.accessToken;

  return useLazyQuery(query, Object.assign({}, options, {
    context: Object.assign({}, options === null || options === void 0 ? void 0 : options.context, {
      headers: Object.assign({}, options === null || options === void 0 ? void 0 : (_options$context = options.context) === null || _options$context === void 0 ? void 0 : _options$context.headers, {
        Authorization: "Bearer " + accessToken
      })
    })
  }));
}

function useAuthenticatedQuery(query, options) {
  var _options$context2;

  var _useAuth2 = useAuth(),
      accessToken = _useAuth2.accessToken;

  return useQuery(query, Object.assign({}, options, {
    context: Object.assign({}, options === null || options === void 0 ? void 0 : options.context, {
      headers: Object.assign({}, options === null || options === void 0 ? void 0 : (_options$context2 = options.context) === null || _options$context2 === void 0 ? void 0 : _options$context2.headers, {
        Authorization: "Bearer " + accessToken
      })
    })
  }));
}

export { useAuthenticatedLazyQuery, useAuthenticatedQuery };