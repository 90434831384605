import _taggedTemplateLiteralLoose from "@babel/runtime/helpers/esm/taggedTemplateLiteralLoose";
import _omit from "lodash-es/omit";
import _merge from "lodash-es/merge";
import _matchesProperty from "lodash-es/matchesProperty";
import _find from "lodash-es/find";

var _templateObject, _templateObject2, _templateObject3;

import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useEffect, useMemo } from 'react';
import { useAuthenticatedMutation, useAuthenticatedQuery, useProfile } from '@/common/hooks';
var artworkDetailQuery = gql(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  query ArtworkDetailsQuery($id: ID!) {\n    artwork: node(id: $id) {\n      ... on Artwork {\n        editionAvailableCount\n        editionSalesCount\n        editionPendingCount\n        editionSize\n        purchasePrice {\n          amount\n          formattedValue\n        }\n        isBuyable\n        artistProfile {\n          portfolio {\n            editionAvailableCount\n            editionSalesCount\n            editionPendingCount\n            editionSize\n            purchasePrice {\n              amount\n              formattedValue\n            }\n          }\n        }\n      }\n    }\n  }\n"])));
var fullscreenResolutionsMutation = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteralLoose(["\n  mutation FullscreenRefreshedArtworkMutation($artworkId: ID!) {\n    fullscreenWatermarkedResourceUrlUltraHigh: generateSignedArtworkUrl(\n      input: { artworkId: $artworkId, width: 2400 }\n    ) {\n      url\n      expirationDateTime\n    }\n    fullscreenWatermarkedResourceUrlHigh: generateSignedArtworkUrl(\n      input: { artworkId: $artworkId, width: 1920 }\n    ) {\n      url\n      expirationDateTime\n    }\n    fullscreenWatermarkedResourceUrlMid: generateSignedArtworkUrl(\n      input: { artworkId: $artworkId, width: 1080 }\n    ) {\n      url\n      expirationDateTime\n    }\n    fullscreenWatermarkedResourceUrlLow: generateSignedArtworkUrl(\n      input: { artworkId: $artworkId, width: 560 }\n    ) {\n      url\n      expirationDateTime\n    }\n  }\n"])));
var userCollectionQuery = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteralLoose(["\n  query UsersRefreshedCollectionQuery {\n    viewer {\n      collection {\n        artwork {\n          ... on Artwork {\n            id\n          }\n        }\n      }\n    }\n  }\n"])));

var updateArtwork = function updateArtwork(artwork, updatedData, updatedFullscreenData) {
  var _updatedFullscreenDat, _updatedFullscreenDat2, _updatedFullscreenDat3, _updatedFullscreenDat4;

  if (!artwork) {
    return null;
  }

  return _merge(artwork, _omit(updatedData.artwork, ['__typename']), updatedFullscreenData ? {
    fullscreenWatermarkedResourceUrlUltraHigh: (_updatedFullscreenDat = updatedFullscreenData.fullscreenWatermarkedResourceUrlUltraHigh) === null || _updatedFullscreenDat === void 0 ? void 0 : _updatedFullscreenDat.url,
    fullscreenWatermarkedResourceUrlHigh: (_updatedFullscreenDat2 = updatedFullscreenData.fullscreenWatermarkedResourceUrlHigh) === null || _updatedFullscreenDat2 === void 0 ? void 0 : _updatedFullscreenDat2.url,
    fullscreenWatermarkedResourceUrlMid: (_updatedFullscreenDat3 = updatedFullscreenData.fullscreenWatermarkedResourceUrlMid) === null || _updatedFullscreenDat3 === void 0 ? void 0 : _updatedFullscreenDat3.url,
    fullscreenWatermarkedResourceUrlLow: (_updatedFullscreenDat4 = updatedFullscreenData.fullscreenWatermarkedResourceUrlLow) === null || _updatedFullscreenDat4 === void 0 ? void 0 : _updatedFullscreenDat4.url
  } : {});
};

function useRefreshedArtwork(_ref) {
  var artwork = _ref.artwork,
      _ref$interval = _ref.interval,
      interval = _ref$interval === void 0 ? 60000 : _ref$interval,
      _ref$updateFullscreen = _ref.updateFullscreenResolutionResources,
      updateFullscreenResolutionResources = _ref$updateFullscreen === void 0 ? false : _ref$updateFullscreen;
  var artworkId = useMemo(function () {
    return artwork === null || artwork === void 0 ? void 0 : artwork.id;
  }, [artwork]);

  var _useProfile = useProfile(),
      isLoggedIn = _useProfile.isLoggedIn;

  var _useAuthenticatedMuta = useAuthenticatedMutation(fullscreenResolutionsMutation, {}),
      requestFullscreenArtworks = _useAuthenticatedMuta[0],
      fullscreenData = _useAuthenticatedMuta[1].data;

  var _useAuthenticatedQuer = useAuthenticatedQuery(userCollectionQuery, {
    skip: !isLoggedIn || !updateFullscreenResolutionResources,
    ssr: false,
    fetchPolicy: 'no-cache',
    pollInterval: interval,
    notifyOnNetworkStatusChange: true
  }),
      collectionData = _useAuthenticatedQuer.data;

  var userOwnsArtwork = useMemo(function () {
    var _collectionData$viewe;

    if (!artworkId) {
      return false;
    }

    if (!isLoggedIn) {
      return false;
    }

    if (!collectionData) {
      return false;
    }

    return !!_find((_collectionData$viewe = collectionData.viewer) === null || _collectionData$viewe === void 0 ? void 0 : _collectionData$viewe.collection, // eslint-disable-next-line lodash/matches-prop-shorthand
    _matchesProperty('artwork.id', artworkId));
  }, [collectionData, isLoggedIn, artworkId]);
  useEffect(function () {
    if (isLoggedIn && updateFullscreenResolutionResources && userOwnsArtwork && artworkId) {
      requestFullscreenArtworks({
        variables: {
          artworkId: artworkId
        }
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [isLoggedIn, updateFullscreenResolutionResources, userOwnsArtwork]);

  var _useQuery = useQuery(artworkDetailQuery, {
    variables: {
      id: artworkId !== null && artworkId !== void 0 ? artworkId : ''
    },
    skip: !artworkId || (artwork === null || artwork === void 0 ? void 0 : artwork.editionAvailableCount) === 0,
    ssr: false,
    fetchPolicy: 'no-cache',
    pollInterval: interval,
    notifyOnNetworkStatusChange: true
  }),
      data = _useQuery.data;

  var updatedArtwork = data && artwork ? updateArtwork(artwork, data, fullscreenData) : artwork;
  return {
    artwork: updatedArtwork
  };
}

export { useRefreshedArtwork };