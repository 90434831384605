import { useStoreActions, useStoreState } from '@/common/providers/easy-peasy/hooks';

function useVolume() {
  var storedVideoMuted = useStoreState(function (state) {
    return state.volume.storedVideoMuted;
  });
  var setVideoMuted = useStoreActions(function (actions) {
    return actions.volume.setVideoMuted;
  });
  return {
    storedVideoMuted: storedVideoMuted,
    setVideoMuted: setVideoMuted
  };
}

export { useVolume };