import React, { useEffect, useState } from 'react';
import useEffectOnce from "react-use/esm/useEffectOnce";
import { useScrollStore } from '@/common/hooks';
import { useScrollTop } from '@/common/hooks/raf-viewport-scroll-position-hook';
import { jsx as ___EmotionJSX } from "@emotion/core";

var ScrollRestore = function ScrollRestore(_ref) {
  var uri = _ref.uri;
  var currentScroll = useScrollTop(5);

  var _useState = useState(false),
      scrollRestorationReady = _useState[0],
      setScrollRestorationReady = _useState[1];

  var _useScrollStore = useScrollStore(),
      currentPageStoredScrollPosition = _useScrollStore.currentPageStoredScrollPosition,
      setStoredScrollPosition = _useScrollStore.setStoredScrollPosition,
      getStoredScrollPosition = _useScrollStore.getStoredScrollPosition,
      prevUri = _useScrollStore.prevUri,
      setPrevUri = _useScrollStore.setPrevUri;

  useEffectOnce(function () {
    if (prevUri !== null && prevUri !== void 0 && prevUri.includes(uri)) {
      getStoredScrollPosition(uri);
      setScrollRestorationReady(true);
    }

    setPrevUri(uri); // eslint-disable-next-line react-hooks/exhaustive-deps
  });
  useEffect(function () {
    if (scrollRestorationReady && currentPageStoredScrollPosition) {
      setTimeout(function () {
        if (typeof window !== 'undefined') {
          window.scrollTo({
            top: currentPageStoredScrollPosition
          });
        }
      }, CONFIG.scrollRestorationDelay);
    } // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [scrollRestorationReady]);
  useEffect(function () {
    if (uri) {
      setStoredScrollPosition({
        scrollPosition: currentScroll,
        uri: uri
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [currentScroll]);
  return ___EmotionJSX(React.Fragment, null);
};

export { ScrollRestore };